import * as React from "react"
import ContactPageTemplate from "../templates/Contact"


const IndexPage = () => {
  return (
    <ContactPageTemplate/>
  )
}

export default IndexPage
