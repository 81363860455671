import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { MapPin, Smartphone, Mail } from 'react-feather'
import styled from 'styled-components'
import showdown from 'showdown'
import Layout from '../components/Layout'
import PageHero from '../components/PageHero'
import FormSimpleAjax from '../components/FormSimpleAjax'
// import GoogleMap from '../components/GoogleMap'
import InstagramLogo from '../icons/instagram-logo.svg'
import TwitterLogo from '../icons/twitter-logo.svg'

const ContactGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: var(--bp-xlarge);
  margin: 0 auto;
  padding: 4rem 0;
  justify-content: space-between;
`

const ContactGridItem = styled.div`
  width: calc(50% - 2rem);
  .footer__sn {
    margin-bottom: 0;
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    .footer__sn {
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  .footer__sn a {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    margin-right: 1rem;
    vertical-align: middle;
  }

  .footer__sn svg {
    width: 100%;
    max-height: 100%;
  }
`

const SocialNetworks = styled.div`
  margin-bottom: 0;
  width: 100%;
  a {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    margin-right: 1rem;
    vertical-align: middle;
  }
  svg {
    width: 100%;
    max-height: 100%;
  }
`

const ContactDetailsIntro = styled.div`
  width: 100%;
  margin-bottom: 4rem;
`

const ContactDetailsItem = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`


const converter = new showdown.Converter()

const ContactPageTemplate = () => (
  <StaticQuery
    query={graphql`
      {
        markdownRemark(frontmatter: {template: {eq: "ContactPage"}}) {
          frontmatter {
            title
            subtitle
            phone
            contact_body
            email
            address
            hero_image
            locations {
              lat
              lng
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Layout>
          <PageHero title={data.markdownRemark.frontmatter.title} body={data.markdownRemark.frontmatter.subtitle} image={data.markdownRemark.frontmatter.hero_image} />
          <ContactGrid>
            <ContactGridItem>
              <ContactDetailsIntro>
                <div
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(
                      data.markdownRemark.frontmatter.contact_body
                    ),
                  }}
                />
              </ContactDetailsIntro>
              {data.markdownRemark.frontmatter.address && (
                <ContactDetailsItem>
                  <a
                    className="Contact--Details--Item"
                    href={`https://www.google.com.au/maps/search/${encodeURI(
                      data.markdownRemark.frontmatter.address
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MapPin /> {data.markdownRemark.frontmatter.address}
                  </a>
                </ContactDetailsItem>
              )}
              {data.markdownRemark.frontmatter.phone && (
                <ContactDetailsItem>
                  <a className="Contact--Details--Item" href={`tel:${data.markdownRemark.frontmatter.phone}`}>
                    <Smartphone /> {data.markdownRemark.frontmatter.phone}
                  </a>
                </ContactDetailsItem>
              )}
              {data.markdownRemark.frontmatter.email && (
                <ContactDetailsItem>
                  <a className="Contact--Details--Item" href={`mailto:${data.markdownRemark.frontmatter.email}`}>
                    <Mail /> {data.markdownRemark.frontmatter.email}
                  </a>
                </ContactDetailsItem>
              )}
              <SocialNetworks>
              <a href="https://twitter.com/NutriPDGMIT" target="_blank" rel="noreferrer">
                  <TwitterLogo />
                </a>
                <a href="https://www.instagram.com/nutripdgmit/" target="_blank" rel="noreferrer">
                  <InstagramLogo />
                </a>
              </SocialNetworks>
            </ContactGridItem>
            <ContactGridItem>
              <FormSimpleAjax name="Simple Form Ajax" />
            </ContactGridItem>
          </ContactGrid>
          {/* <GoogleMap locations={data.markdownRemark.frontmatter.locations} /> */}
        </Layout>
      </>
    )}></StaticQuery>
)


export default ContactPageTemplate